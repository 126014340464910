import defaultTheme from "@lekoarts/gatsby-theme-cara/src/gatsby-plugin-theme-ui";

const theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    ...defaultTheme.colors.modes.light,
  },
};

export default theme;
